<template>
    <Layout>
        <b-card v-if="!load">
            <FilterBase session="Mentoring" :filter="filter" :button="true" url="/mentorias/cadastrar"/>
            <div class="row">
                <div class="col text-end" id="view_mentoring">
                    <span class="avatar avatar-sm mr-12pt pointer" :class="view === 'table' ? 'active' : ''"
                          @click="view = 'table'">
                        <span class="avatar-title rounded">
                            <i class="fa fa-list" :class="view === 'table' ? 'text-primary' : 'text-muted'"></i>
                        </span>
                    </span>
                    <span class="avatar avatar-sm pointer" :class="view === 'cards' ? 'active' : ''"
                          @click="view = 'cards'">
                        <span class="avatar-title rounded">
                            <i class="fa fa-table" :class="view === 'cards' ? 'text-primary' : 'text-muted'"></i>
                        </span>
                    </span>
                </div>
            </div>
            <div class="mb-0 mt-3" v-if="view === 'table'">
                <TableList session="Mentoring" url="mentorias/" :table="table" :keys="keys" ref="tableData">
                    <template #img="{ value }">
                        <div class="avatar avatar-sm mr-8pt">

                            <img v-show="loaded.indexOf(value.id) > -1" :src="value.logoUrl" alt="Avatar" class="avatar-img rounded-circle"
                                 @load="loaded.push(value.id)">
                            <b-skeleton type="avatar" v-show="loaded.indexOf(value.id) < 0"></b-skeleton>
                        </div>
                    </template>

                    <template #tags="{ value }">
                        <div v-if="value.tags.length > 0">
                            <b-badge variant="info" class="m-1 fs-12 p-1" v-for="tag in value.tags"
                                     :key="'badge-tags-list-' + tag.id">
                                {{ tag.tag }}
                            </b-badge>
                        </div>
                        <div v-else></div>
                    </template>

                    <template #value="{ value }">
                        {{ maskMoneyTable(value.price) }}
                    </template>

                    <template #status="{ value }">
                        <ChangeStatus :data="value" url="mentorings/"/>
                    </template>

                    <template #actions="{ value }">
                        <Actions :id="value.id" :types="types" url="mentorias/" api="mentorings/" :extra="extraButtons"
                                 :data="value" @activate="activate"/>
                    </template>

                </TableList>
            </div>

            <MyCourses class="mt-3" v-else/>

        </b-card>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue'
import MyCourses from "./my-courses.vue";
import TableList from "@/components/base/table-list.vue";
import FilterBase from '@/components/base/filter-component.vue';
import ChangeStatus from "@/components/base/change-status.vue";
import Actions from "@/components/base/actions.vue";
import {setSessions} from "@/components/composables/setSessions";
import {filter} from '../js/filter'
import {table, keys} from "@/views/mentoring/js/table";
import {maskMoneyTable} from "@/components/composables/masks";
import http from "@/http";

export default {
    methods: {
        maskMoneyTable,
        async activate(id) {
            let self = this
            self.load = true;
            let response = await http.get('mentorings/change-status/' + id)

            try {
                self.$notifySuccess(response.data);
                localStorage.removeItem('Mentoring')
                self.load = false

            } catch (errors) {

                console.log('Change Status: ', errors.response.data.message);
                self.$notifyError(errors.response.data);
            }

        }
    },
    components: {
        Layout,
        MyCourses,
        FilterBase,
        TableList,
        ChangeStatus,
        Actions
    },
    data() {
        return {
            load: false,
            view: 'table',
            types: ['edit', 'details', 'copy'],
            filter,
            table,
            keys,
            loaded: [],
            extraButtons: [
                {
                    title: 'Ativar/Inativar',
                    class: 'fa fa-check text-info mx-1',
                    callback: 'activate'
                }
            ]
        }
    },
    mounted() {
        if (!localStorage.getItem('Mentoring')) setSessions('Mentoring');
    }
}
</script>
